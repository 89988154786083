<template>
  <div class="action-card">
    <div class="action-card__body action-body">
      <img v-if="image" class="action-body__logo" :src="image" />
      <div class="action-body__title">
        {{ title }}
      </div>
      <div class="action-body__description">
        <slot />
      </div>
      <div class="action-body__footer">
        <ui-button
          outline
          data-popup-with-chats
          data-popup-with-chats-title="Узнать подробнее"
        >
          Узнать Подробнее
        </ui-button>
      </div>
    </div>
  </div>
</template>

<script>
import UiButton from "@/components/ui/UIButton";

export default {
  name: "ActionCard",
  components: {
    UiButton,
  },
  props: {
    image: {
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    to: {
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.action-card {
  padding: 40px;
  background-color: #ffffff;

  &__body,
  .action-body {
    &__logo {
      height: 40px;
      margin-bottom: 40px;
    }

    &__title {
      font-family: $font-family-bebas;
      font-style: normal;
      font-weight: normal;
      font-size: 26px;
      line-height: 105%;
      letter-spacing: 0.05em;
      color: #18181b;
      margin-bottom: 15px;

      @media screen and (min-width: $screen-sm) {
        font-size: 36px;
      }
    }
    &__description {
      font-family: $font-family-rubik;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 170%;
      color: #18181b;
      opacity: 0.7;
    }
    &__footer {
      margin-top: 40px;
    }
  }
}
</style>
