var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "action-card" }, [
    _c("div", { staticClass: "action-card__body action-body" }, [
      _vm.image
        ? _c("img", {
            staticClass: "action-body__logo",
            attrs: { src: _vm.image },
          })
        : _vm._e(),
      _c("div", { staticClass: "action-body__title" }, [
        _vm._v(" " + _vm._s(_vm.title) + " "),
      ]),
      _c(
        "div",
        { staticClass: "action-body__description" },
        [_vm._t("default")],
        2
      ),
      _c(
        "div",
        { staticClass: "action-body__footer" },
        [
          _c(
            "ui-button",
            {
              attrs: {
                outline: "",
                "data-popup-with-chats": "",
                "data-popup-with-chats-title": "Узнать подробнее",
              },
            },
            [_vm._v(" Узнать Подробнее ")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }